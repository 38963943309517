@import '~common/colors';

.iconLink {
  display: initial;
  padding: 0;
  margin-right: 5px;

  border: none;
  font-size: inherit;
  color: inherit;

  background-color: transparent;
  line-height: inherit;

  &.disabled {
    cursor: default;
  }
}

.icon {
  width: 30px;
  vertical-align: middle;

  border-radius: 2px;
  line-height: 30px;
  cursor: pointer;

  &:not(.inverted) {
    border: 1px solid $light-grey;

    &:hover { color: $tci-purple; }
  }

  &.inverted {
    background-color: $tci-purple;
    border: 1px solid $tci-purple;
    color: white;
  }

  &.disabled {
    background-color: $light-grey;
    color: $med-grey;

    &:hover {
      color: $med-grey;
      cursor: default;
    }
  }
}
